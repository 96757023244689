import { Typography } from '@mui/material';
import Head from 'next/head';
import React from 'react';
import Main from '../layouts/Main';

export default function NotFound() {
  return (
    <Main header_title="Page Not Found">
      <Head>
        <title>Fosterburg Baptist Church | Page Not Found</title>
      </Head>
      <div style={{ margin: 15 }}>
        <Typography>We couldn&lsquo;t find the page you were looking for.</Typography>
        <Typography>Try selecting something from the navigation menu above.</Typography>
      </div>
    </Main>
  );
}
